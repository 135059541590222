<template>
  <div>
    <div class="footer center" v-if="path !== '/login'">
      <div class="footer_list center">
        <div class="box boxone">
          <!-- <div class="image" v-if="istrue"></div>
        <div class="images" v-else></div> -->
          <!-- <div>
          <div v-if="!istrue">主办单位：新疆煤炭交易中心  伊犁州发展和改革委员会  伊宁市发展和改革委员会</div>
          <div v-if="!istrue" class="middle">承办单位：伊宁市联创城市建设（集团）有限责任公司</div>
           <div v-if="istrue">主办单位：新疆煤炭交易中心</div>
          <div v-if="istrue" class="middle">承办单位：新疆煤炭交易中心</div>
          <div class="middle">Copyright(C) 2018 新疆煤炭交易中心有限公司</div>
        </div> -->
          <div v-if="istrue" style="margin-top: 56px">
            <div><img src="../assets/home_img/7.png" alt="" /></div>
            <div @click="TClick" style="margin-top: 28px">
              <div class="shou">新ICP备17000837号-1</div>
              <!-- <div class="shou" style="margin-top: 13px">
                新ICP备：17000837号-1
              </div> -->
            </div>
			<div @click="GClick" style="margin-top: 14px">
			  <div class="shou">新公网安备65220102000175号</div>
			  <!-- <div class="shou" style="margin-top: 13px">
			    新ICP备：17000837号-1
			  </div> -->
			</div>
          </div>
          <div v-else @click="TClick" style="margin-top: 56px">
            <div><img src="../assets/home_img/7.png" alt="" /></div>
            <div @click="TClick" style="margin-top: 28px">
              <div class="shou">新ICP备17000837号-1</div>
              <!-- <div class="shou" style="margin-top: 13px">
                新ICP备：17000837号-1
              </div> -->
            </div>
            <div @click="GClick" style="margin-top: 14px">
              <div class="shou">新公网安备65220102000175号</div>
              <!-- <div class="shou" style="margin-top: 13px">
                新ICP备：17000837号-1
              </div> -->
            </div>
          </div>

          <div class="bottom_img" style="margin-top: 51px">
            <img src="../assets/home_img/6.png" alt="" />
            <div style="margin-top: 8px">扫码关注我们</div>
          </div>
        </div>
        <!-- <div class="boxtow">
        <div class="qecode" :key="item" v-for="item in list">
          <div class="qecodeone"></div>
          <div class="weixin">
            <div class="b">{{ item }}</div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
    <div class="footers" v-else>
      <div class="footers_on">
        <div @click="TClick" style="margin-top: 28px">
          <div class="shou">新ICP备17000837号-1</div>
          <!-- <div class="shou" style="margin-top: 13px">
            新ICP备：17000837号-1
          </div> -->
        </div>
        <div @click="GClick" style="margin-top: 14px">
          <div class="shou">新公网安备65220102000175号</div>
          <!-- <div class="shou" style="margin-top: 13px">
            新ICP备：17000837号-1
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // '安卓版下载','苹果版下载','官方小程序','官方公众号'
      list: [],
      istrue: true,
      path: "",
    };
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
  mounted() {
    let a = document.URL;
    var str1 = a.substring(0, a.indexOf("#"));
    if (str1 == "https://north.xjcec.com/") {
      this.istrue = false;
    } else {
      this.istrue = true;
    }
  },
  methods: {
    TClick() {
      window.open("https://beian.miit.gov.cn/");
    },
	GClick() {
	  window.open("https://beian.mps.gov.cn/#/query/webSearch");
	},
  },
};
</script>
<style lang="scss" scoped>
.bottom_img {
  img {
    width: 110px;
    height: 110px;
  }
}
.footers {
  width: 100%;
  min-width: 1903px;
  height: 60px;
  background-color: #454545;
  color: white;

  font-size: 16px;
}
.footer {
  height: 242px;
  width: 100%;
  min-width: 1903px;
  /* line-height: 80px; */
  background-color: #454545;

  .footer_list {
    /* width: 1400px; */
    width: 1440px;
    /* display: flex; */
    /* height: 238px; */
    /* justify-content: space-between; */
    text-align: center;

    // align-items: center;
    .box {
      color: #fff;
      // width: 50%;
      height: 100%;

      .image {
        width: 294px;
        height: 62px;
        background: url("../assets/images/logo_footer.png") no-repeat;
        background-size: 100% 100%;
      }

      .images {
        width: 294px;
        height: 68px;
        background: url("../assets/zxx_img/bei/logoW.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .boxone {
      /* // width: 380px; */
      /* padding: 60px 0; */
      display: flex;
      justify-content: space-between;
      color: #ccc;

      .middle {
        margin: 10px 0 10px 0;
      }

      span + span {
        display: inline-block;
        margin-left: 20px;
        color: #ccc;
      }
    }

    .boxtow {
      width: 635px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 39px;

      .qecode {
      }

      .weixin {
        text-align: center;

        .b {
          margin-top: 5px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #cccccc;
        }
      }

      .qecodeone {
        width: 100px;
        height: 100px;
        // background-color: #fff;
        background: url("../assets/img/qrcode1.png") no-repeat;
        background-size: 100% 100%;
      }

      .qecode :nth-child(2) {
        // display: flex;
      }
    }
  }
}

.shou {
  text-align: left;
  cursor: pointer;
}
.footers_on {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 22px;
  letter-spacing: 1px;
}
.marginleft10 {
  margin-left: 10px;
}
</style>
