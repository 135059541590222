export default [
  // 商城
  {
    path: '/mall',
    name: 'mall',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/mall/mall'),
    meta: {
      title: '挂牌销售',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  // 拼团交易
  {
    path: '/piecing',
    name: 'piecing',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/piecing'),
    meta: {
      title: '拼团交易',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },

   // 物流竞价
   {
    path: '/LogisticsBidding',
    name: 'Logistics',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/Logistics'),
    meta: {
      title: '物流竞价',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  {
    path: '/piecingTrade',
    name: 'piecingTrade',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/piecing/components/piecingTrade'),
    meta: {
      title: '发布拼团交易',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }, {
        name: '拼团交易',
        path: '/piecing'
      }]
    }
  },
  // 发布物流竞价
  {
    path: '/PublishLogistics',
    name: 'PublishLogistics',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/Logistics/components/PublishLogistics'),
    meta: {
      title: '发布物流竞价',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }, {
        name: '物流竞价',
        path: '/LogisticsBidding'
      }]
    }
  },
  {
    path: '/logisticsDetails',
    name: 'logisticsDetails',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/Logistics/components/logisticsDetails'),
    meta: {
      title: '详情',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }, {
        name: '物流竞价',
        path: '/LogisticsBidding'
      }]
    }
  },



  {
    path: '/piecingDetails',
    name: 'piecingDetails',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/piecing/components/piecingDetails'),
    meta: {
      title: '发布拼团交易',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }, {
        name: '拼团交易',
        path: '/piecing'
      }]
    }
  },
  {
    path: '/addGoods',
    name: 'addGoods',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/mall/addGoods'),
    meta: {
      title: '发布挂牌信息',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }, {
        name: '挂牌采购',
        path: '/mall'
      }]
    }
  },
  {
    path: '/addBiddingGoods',
    name: 'addBiddingGoods',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/mall/addBiddingGoods'),
    meta: {
      title: "发布竞价商品",
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }, {
        name: '竞价商品',
        path: '/mall',
      }]
    }
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import( /* webpackChunkName: "mall" */ '@/page/mall/goodsDetail'),
    meta: {
      title: '详情',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },

  // 利润计算器
  {
    path: '/profit',
    name: 'profit',
    component: () => import( /* webpackChunkName: "profit" */ '@/page/mall/profit'),
    meta: {
      title: '利润计算器',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  // 会员认证
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import( /* webpackChunkName: "authentication" */ '@/page/userCenter/authentication/authentication'),
    meta: {
      title: '企业认证',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  // 中长期会员认证
  {
    path: '/MediumTerm',
    name: 'MediumTerm',
    component: () => import( /* webpackChunkName: "MediumTerm" */ '@/page/userCenter/MediumTerm/MediumTerm'),
    meta: {
      title: '中长期会员认证',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  //开通中金账户
	{
		path: '/opencicc',
		name: '',
		component: () => import( /* webpackChunkName: "myAssets" */ "@/page/userCenter/manage/opencicc"),
   		meta: {
				title: '我的银行卡',
				breadcrumbList: [{
					name: '首页',
					path: '/'
				}, {
					name: '个人中心',
					path: '/userIndex'
				}]
			}
		// children: [{
		// 	path: "/opencicc",
		// 	name: "opencicc",
		// 	component: () =>
		// 		import(
		// 			"@/page/userCenter/manage/opencicc.vue"
		// 		),
		// 	meta: {
		// 		title: '我的银行卡',
		// 		breadcrumbList: [{
		// 			name: '首页',
		// 			path: '/'
		// 		}, {
		// 			name: '个人中心',
		// 			path: '/userIndex'
		// 		}]
		// 	}
		// }, ]
	},
  // 人员权限
  {
    path: '/personnelStructure',
    name: '',
    component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
    redirect: '/personnelStructure',
    children:[
      {
        path: '/personnelStructure',
        name: 'personnelStructure',
        component: () => import( /* webpackChunkName: "personnelStructure" */ '@/page/userCenter/personnelStructure/personnelStructure'),
        meta: {
          title: '人员权限',
          breadcrumbList: [{
            name: '首页',
            path: '/'
          }]
        }
      },
    ]
  },
  {
    path: '/personnelStructure/setPower',
    name: 'personnelStructureSetPower',
    component: () => import( /* webpackChunkName: "personnelStructure" */ '@/page/userCenter/personnelStructure/setPower'),
    meta: {
      title: '人员架构',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  // 我的资产
  {
    path: '/myAssets',
    name: '',
    component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/userCenterMain'),
    redirect: '/myAssets',
    children:[
      { 
				path: "/myAssets",
				component: () =>
					import("@/page/userCenter/myAssets/myAssets"),
          meta: {
            title: '我的资产',
            breadcrumbList: [{
              name: '首页',
              path: '/'
            }, {
              name: '个人中心',
              path: '/userIndex'
            }]
          },
			},
    ]
  },
  {
    path: '/rechargeRecord',
    name: 'rechargeRecord',
    component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/myAssets/rechargeRecord'),
    meta: {
      title: '充值记录',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  {
    path: '/withdrawalRecord',
    name: 'withdrawalRecord',
    component: () => import( /* webpackChunkName: "myAssets" */ '@/page/userCenter/myAssets/withdrawalRecord'),
    meta: {
      title: '提现记录',
      breadcrumbList: [{
        name: '首页',
        path: '/'
      }]
    }
  },
  {
    path: '/priceIndex',
    name: 'priceIndex',
    component: () => import( /* webpackChunkName: "priceIndex" */ '@/page/priceIndex/index.vue'),
    meta: {
      title: '价格指数',
    }
  },
  {
    path: '/priceIndex/detail',
    name: 'priceIndex/detail',
    component: () => import( /* webpackChunkName: "priceIndex" */ '@/page/priceIndex/detail.vue'),
    meta: {
      title: '价格指数',
    }
  },
];